import { LeadService } from './../../services/lead.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RequestAction, ResponseAction } from './actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class SuperannuationProviderSearchEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: LeadService) {
  }

  providerSearch$ = createEffect(() => this.actions$.pipe(
    ofType(RequestAction),
    switchMap((action: any) => this.service.providerSearch(action.data)),
    map((data: any) => {
      return ResponseAction({ payload: data.data });
    })
  ));

}

<div class="member-join-container d-flex align-items-center m-0 flex-column " [ngClass]="{'mobile': innerWidth < 800}">
  <mat-progress-bar class="loading" mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>
  <div class="w-100 h-100">
    <div class="form-content d-flex flex-column ">


      <div class="member-join-row ">

        <canvas></canvas>
        <!-- <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-10 p-0">
            <div class="d-flex align-items-center justify-content-start pl-0 pr-0 pt-4 pb-2">
              <div class="logo flex-fill">
                <img class="" src="https://vervassets.blob.core.windows.net/web/logo-small.png">
              </div>
            </div>
            <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
              <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
            </div>
          </div>
          <div class="col-lg-1">

          </div>
        </div> -->


        <div class="flex-fill">
          <div class=" pt-0">
            <ng-template #errorPage>
              <div appanimatecss class="error-page" *ngIf="errorPage$ | async; let errorPage">
                <h4 class="description">
                  We are unable to complete your application. Please <a href="https://vervesuper.com.au/contact-us/"
                    target="_blank">get in touch</a> with our team for assistance.
                </h4>
              </div>

            </ng-template>



            <mat-horizontal-stepper [linear]="true" #stepper [selectedIndex]="(currentStep$ | async)">
              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-1">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img
                          src="https://vervassets.blob.core.windows.net/web/verve-super-logo.svg"
                          class="member-join"></a>
                      <div class="align-items-end flex-fill">
                        <img src="https://vervassets.blob.core.windows.net/web/firstpage-people.webp"
                          *ngIf="innerWidth >= 800" class="member-join-people d-flex ">
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin " appanimatecss>
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" *ngIf="form$ | async; let form" class="w-100" autocomplete="off"
                        role="presentation">
                        <div id="login-container">
                          <div class="p-4">
                            <!-- <h1>Build wealth and invest in a better world</h1> -->
                            <h3 class="subheader">Join Verve Super in less than 5 minutes</h3>
                            <div class="main-container">
                              <div class="content ">
                                <div class="row">
                                  <div class="col col-md-12 p-0">
                                    <app-entry class="entry-dark-bg" title="First name (the one on your ID)"
                                      [control]="form.controls.firstName">
                                    </app-entry>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col col-md-12 p-0">
                                    <app-entry class="entry-dark-bg" title="Last name (the one on your ID)"
                                      [control]="form.controls.lastName">
                                    </app-entry>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col col-md-12 p-0">
                                    <app-entry class="entry-dark-bg" title="Email" entrytype="email"
                                      [control]="form.controls.email">
                                    </app-entry>

                                  </div>
                                </div>

                              </div>
                              <div class="card-bottom row mt-1">
                                <div class="col col-md-12 pl-0 pr-0">
                                  <app-button class=" custom float-right" title="Next" icon="east" loading
                                    [isDisabled]=" (isLoading$ | async)" (onClick)="onCreateLeadClick(form)">
                                  </app-button>
                                </div>

                              </div>
                              <div class="footer mt-3">
                                <h5 class="mb-0">Need help? <a href="https://vervesuper.com.au/contact-us/"
                                    target="_blank"><b>Contact us</b>.</a>
                                </h5>
                                <p>
                                  By providing your email address, you consent and authorise us to send you
                                  communications or information,
                                  including information required by law, via email or similar technologies. Your details
                                  will never be passed onto
                                  a third party other than in accordance
                                  with our <a href="https://vervesuper.com.au/privacy-policy/" target="_blank">Privacy
                                    Policy</a>. You can elect to receive communications by post at any time
                                  by contacting Verve Super
                                  on 1300 799 482 or via email at <a href="mailto:hello@vervesuper.com.au"
                                    target="_blank">hello@vervesuper.com.au</a> or in writing at GPO Box 2753, Brisbane
                                  QLD 4001.
                                </p>
                                <p>
                                  All information is of a general nature only. As always, it’s important to do your own
                                  research and consider
                                  things like fees, investment performance, insurance, risk profile, and alignment with
                                  your values when
                                  considering if Verve Super is right for
                                  you. Consider seeking financial advice if you need help determining if Verve Super
                                  aligns with your financial
                                  needs. When considering performance, past performance is not indicative of future
                                  performance. <a href="https://vervesuper.com.au/fund-information/"
                                    target="_blank">Fund
                                    information.</a>
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </form>
                    </ng-container>

                    <ng-container *ngTemplateOutlet="errorPage">
                    </ng-container>
                  </div>
                </div>

              </mat-step>

              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-2">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">

                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>

                      <h1>About <b>you</b></h1>
                      <!-- <h4 class="subheader">Tell us a little more about yourself</h4> -->
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">

                      <div class="footer footer-desktop">
                        <ng-template class="" [ngTemplateOutlet]="FooterStep1"></ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin" appanimatecss>
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" class="w-100" autocomplete="off" role="presentation">
                        <div class="main-container">
                          <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                            <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                          </div>

                          <div class="content ">
                            <h3>Tell us a little more about yourself</h3>
                            <div class="row">
                              <div class="col col-md-12  ">
                                <app-entry class="entry-dark-bg" title="First name" [control]="form.controls.firstName"
                                  (onInputBlur)="onInputValueChanged(form.controls.firstName,form.value, $event)">
                                </app-entry>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col col-md-12 ">
                                <app-entry class="entry-dark-bg" title="Last name" [control]="form.controls.lastName"
                                  (onInputBlur)="onInputValueChanged(form.controls.lastName,form.value, $event)">
                                </app-entry>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col col-md-12 ">
                                <app-entry-date class="entry-dark-bg" title="Date of birth" [date]="form.controls.dob"
                                  [maxdate]="today"
                                  (onValueChange)="onInputValueChanged(form.controls.dob,form.value, $event)">
                                </app-entry-date>
                              </div>
                            </div>
                            <div class="row dob-gender">
                              <div class="col col-md-6 dob">
                                <app-entry class="entry-dark-bg" title="Mobile" [control]="form.controls.mobile"
                                  (onInputBlur)="onInputValueChanged(form.controls.mobile,form.value, $event)">
                                </app-entry>
                              </div>
                              <div class="col col-md-6 ">
                                <app-entry-dropdown class="entry-dark-bg" title="Gender" list="gender"
                                  (onValueChange)="onInputValueChanged(form.controls.gender,form.value, $event)"
                                  [control]="form.controls.gender">
                                </app-entry-dropdown>
                              </div>
                            </div>


                            <div class="row">
                              <div class="col col-md-12 ">
                                <app-entry-address-accurity class="entry-dark-bg" title="Address" country='AU'
                                  [address]="form.controls.address" [allowOverseasAddress]="false"
                                  (onValueChange)="onInputValueChanged(form.controls.address,form.value, $event)">
                                </app-entry-address-accurity>

                              </div>
                            </div>


                          </div>
                          <div class="card-bottom row mt-3 mb-4">
                            <div class="col col-md-12 d-flex pl-0 pr-0 d-flex">
                              <app-button class=" back-btn" title="Back" color="light" icon="west"
                                (onClick)="onMoveBackClick(stepper)">
                              </app-button>

                              <div class="flex-fill"></div>

                              <app-button class=" custom" title="Next" [isDisabled]=" (isLoading$ | async)" icon="east"
                                loading (onClick)="onMoveNextClick(form,stepper)">
                              </app-button>

                            </div>
                          </div>
                          <div class="footer-mobile">
                            <ng-template [ngTemplateOutlet]="FooterStep1"></ng-template>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                  </div>

                </div>
              </mat-step>

              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-3">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>

                      <h1 class="">Activate your account</h1>
                      <h5 class="subheader">This helps us verify and secure your account.</h5>
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">

                      <div class="footer footer-desktop">
                        <ng-template class="" [ngTemplateOutlet]="FooterStep2"></ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin" appanimatecss>
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" class="w-100" autocomplete="off" role="presentation">
                        <div class="main-container">
                          <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                            <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                          </div>
                          <div class="content">
                            <h3 *ngIf="(isMobileVerified$ | async) == false">We've just sent you a text message with a
                              verification code, enter it here</h3>
                            <h3 *ngIf="(isMobileVerified$ | async) == true">{{ (sendMobileCodeMessage$ | async)}}</h3>
                            <div class="row">
                              <div class="col col-md-12  p-0" *ngIf="(isMobileVerified$ | async) == false">
                                <app-entry class="entry-dark-bg" title="Validation code"
                                  [control]="form.controls.mobileCode" [autofill]="true" autocomplete="one-time-code">
                                </app-entry>
                                <div class="d-flex">
                                  <a class="flex-fill click no-text-decoration" (click)="sendMobile(form.value.mobile)"
                                    [ngClass]="{'locked': resendLocked == true}">Resend SMS to
                                    {{form.value.mobile}} <span class="resend-time"
                                      *ngIf="resendLocked">({{resendLockedTime}}
                                      secs)</span></a>
                                  <a style="text-align: end" class="flex-fill click no-text-decoration"
                                    (click)="onMoveBackClick(stepper)">Change mobile number</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-bottom row mt-4 mb-4" style="margin-top: 40px !important;">
                            <div class="col col-md-12 pr-0 pl-0 d-flex">
                              <app-button class=" back-btn" title="Back" color="light" icon="west"
                                (onClick)="onMoveBackClick(stepper)">
                              </app-button>
                              <div class="flex-fill"></div>
                              <app-button class=" custom" title="Next" [isDisabled]=" (isLoading$ | async)" icon="east"
                                loading (onClick)="onCheckMobileCodeClick(form, form.value.mobileCode)">
                              </app-button>

                            </div>
                          </div>
                          <div class="footer-mobile">
                            <ng-template class="" [ngTemplateOutlet]="FooterStep2"></ng-template>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                  </div>
                </div>

              </mat-step>

              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-4">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>
                      <h1 class="">The nitty gritty</h1>
                      <h4 class="subheader">You’re almost finished</h4>
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">
                      <div class="footer footer-desktop">
                        <ng-template class="" [ngTemplateOutlet]="FooterStep3"></ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin">
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" class="w-100" autocomplete="off" role="presentation">
                        <div class="main-container">
                          <div class="content ">

                            <div class="row">
                              <div class="col col-md-12 p-0">
                                <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                                  <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                                </div>
                                <div class="privacy">
                                  <p>
                                    All the details I have provided in this application form are true and correct. I
                                    have made an informed decision because I have read
                                    the <a href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf"
                                      target="_blank">PDS</a>,
                                    <a href="https://vervesuper.com.au/documents/TMD.pdf" target="_blank">TMD</a>, <a
                                      href="https://vervesuper.com.au/documents/Verve-Super-Additional-Information-Booklet-FINAL.pdf"
                                      target="_blank">Additional Information
                                      Booklet</a> and <a
                                      href="https://vervesuper.com.au/documents/Verve-Insurance-Guide.pdf"
                                      target="_blank">Insurance Guide</a> to which this application applies.

                                  </p>
                                  <app-checkbox class="entry-dark-bg"
                                    title="I have read, understood and agree to the above"
                                    [control]="form.controls.readAllInformationAgreement">
                                  </app-checkbox>

                                  <p class="mt-3">
                                    I understand that 100% of the balance of my account will be invested in Verve
                                    Super’s Balanced investment option.
                                  </p>
                                  <app-checkbox class="entry-dark-bg"
                                    title="I have read, understood and agree to the above"
                                    [control]="form.controls.iUnderstandBalanceInvested">
                                  </app-checkbox>

                                  <p class="mt-3">
                                    I consent to Verve Superannuation Pty Ltd, in its role as Fund Promoter, receiving
                                    all
                                    of the annual flat dollar Administration fee, and a portion of the percentage-based
                                    component of the Administration fee as set out in section 6 of the Verve Super <a
                                      href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf"
                                      target="_blank">PDS</a>.
                                  </p>

                                  <p>
                                    I consent to Future Super Investment Services Pty Ltd, in its role as Investment
                                    Manager receiving all of the Investment fee as set out in section 6 of the Verve
                                    Super
                                    <a href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf"
                                      target="_blank">PDS</a>.
                                  </p>

                                  <app-checkbox class="entry-dark-bg"
                                    title="I have read, understood and agree to the above"
                                    [control]="form.controls.conscentAgreement">
                                  </app-checkbox>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-bottom row mt-3 mb-4">
                            <div class="col col-md-12 pl-0 pr-0 d-flex">
                              <app-button class=" back-btn" title="Back" color="light" icon="west"
                                (onClick)="onMoveBackClick(stepper)">
                              </app-button>
                              <div class="flex-fill"></div>
                              <app-button class=" custom" title="I agree" (onClick)="onMoveNextClick(form,stepper)"
                                loading icon="east" [isDisabled]=" (isLoading$ | async)">
                              </app-button>

                            </div>
                          </div>
                          <div class="footer footer-mobile">
                            <ng-template class="" [ngTemplateOutlet]="FooterStep3"></ng-template>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                  </div>
                </div>

              </mat-step>

              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-5">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>
                      <h1 class="">Nice work!</h1>
                      <h2 class="mt-3 subheader">It's time to enter your Tax File Number</h2>
                      <h4 style="z-index: 1000;" class="subheader">If you need help finding your TFN try searching your
                        payment summaries from
                        your employer, visit your <a href="https://my.gov.au/LoginServices/main/login?execution=e1s1"
                          target="_blank">myGov</a> or call the
                        ATO on <a href="tel:132861">13 28 61</a></h4>
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">
                      <div class="footer footer-desktop">
                        <ng-template class="" [ngTemplateOutlet]="FooterStep4"></ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin">
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" class="w-100" autocomplete="off" role="presentation">
                        <div class="main-container">
                          <div class="content ">

                            <div class="row">
                              <div class="col col-md-12 p-0">
                                <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                                  <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                                </div>
                                <!-- (onInputBlur)="onInputValueChanged(form.controls.tfn,form.value, $event)" -->
                                <app-entry class="entry-dark-bg" title="Tax file number" placeholder="###-###-###"
                                  [control]="form.controls.tfn">
                                </app-entry>
                                <div class="privacy">
                                  <p>
                                    I declare that I have read and understood the important information about my Tax
                                    File Number (TFN) in the <a
                                      href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf"
                                      target="_blank">PDS</a> and consent to the use of my
                                    TFN for the legal purposes stated, including creating and administering my account,
                                    using
                                    the ATO’s SuperMatch service to find my lost or inactive accounts, and actioning my
                                    rollover requests.¹
                                  </p>
                                  <!-- (onValueChange)="onInputValueChanged(form.controls.tfnAgreement,form.value, $event)" -->
                                  <app-checkbox class="entry-dark-bg"
                                    title="I have read, understood and agree to the above"
                                    [control]="form.controls.tfnAgreement">
                                  </app-checkbox>

                                  <p class="mt-2 note">
                                    ¹ Under the Superannuation Industry (Supervision) Act 1993, the Trustee is
                                    authorised
                                    to collect your TFN and to use it for lawful purposes including to administer your
                                    superannuation interest and to provide information to the ATO under government
                                    legislation. The lawful purposes for which your TFN can be used, and the
                                    consequences
                                    for not quoting your TFN, may change in the future, as a result of legislative
                                    changes. You are not obliged to provide your TFN, but there may be tax and other
                                    consequences if you do not. For more information, refer to the PDS or contact us on
                                    1300 799 482. For these reasons, Verve Super has decided that we will not open an
                                    account in your name without a valid TFN. For more information, refer to the PDS or
                                    contact us on 1300 799 482
                                  </p>
                                  <!-- <p>
                                    By providing my TFN, I declare that I have read and understood the important
                                    information about my Tax File
                                    Number in the PDS and consent to providing my TFN for the legal purposes stated,
                                    including finding and
                                    amalgamating my superannuation benefits,
                                    providing information to the ATO, and providing information to another super fund if
                                    I transfer my benefits.
                                  </p> -->
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="card-bottom row mt-3 mb-4">
                            <div class="col col-md-12 pl-0 pr-0">
                              <div class="d-flex ">
                                <app-button class="  back-btn" title="Back" color="light" icon="west"
                                  (onClick)="onMoveBackClick(stepper)">
                                </app-button>
                                <div class="flex-fill"></div>
                                <app-button class=" custom" title="Next" [isDisabled]=" (isLoading$ | async)"
                                  icon="east" loading (onClick)="onMoveNextClick(form, stepper)">
                                </app-button>
                              </div>
                              <!-- <a class="float-right mt-2 w-50 link-button click" (click)="onSkipTFN(stepper)">
                                Continue without my TFN
                              </a> -->

                            </div>

                          </div>
                          <div class="footer footer-mobile">
                            <ng-template class="" [ngTemplateOutlet]="FooterStep4"></ng-template>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                  </div>
                </div>

              </mat-step>

              <mat-step *ngIf="form$ | async; let form">
                <div class="d-flex flex-fill content-steps step-6">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>
                      <h1 class="mt-3">Let's move your super to Verve</h1>
                      <h2 class="subheader">We’ll do the heavy lifting. </h2>
                      <h4 class="subheader">
                        Simply enter your old super fund’s details and
                        we’ll do the rest.<br> <i>P.S. Consolidating your super into one account can help to avoid
                          multiple
                          sets of fees.</i>
                      </h4>
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">
                      <div class="footer footer-desktop">
                        <ng-template class="" [ngTemplateOutlet]="FooterStep5"></ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin">
                    <ng-container *ngIf="!(errorPage$ | async)">
                      <form [ngrxFormState]="form" class="w-100" autocomplete="off" role="presentation">
                        <div class="main-container">
                          <div class="content ">
                            <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                              <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                            </div>
                            <!-- <div class="alert alert-info" *ngIf="form.value.superFundList.length == 1">

                            </div> -->
                            <div class="mb-1 super-fund"
                              *ngFor="let item of form.controls.superFundList.controls; let i = index; trackBy: trackByFn;let last = last;">
                              <div class="float-right" (click)="onRemoveSuperFundClick(i)"
                                *ngIf="form.value.superFundList.length>1">
                                <mat-icon>close</mat-icon>
                              </div>
                              <div class="row" *ngIf="!last">
                                <div class="col col-md-12 p-0">
                                  <app-readonly title="Name of the Fund" [value]="item.value.nameOfSuperFund">
                                  </app-readonly>
                                </div>
                              </div>
                              <div class="row" *ngIf="last">
                                <div class="col col-md-12 p-0">
                                  <app-superannuation-provider-search (onSelected)="onSelectedOption(i, $event)">
                                  </app-superannuation-provider-search>

                                </div>
                              </div>
                              <div class="row" *ngIf="item.value.usi">
                                <div class="col col-md-12 p-0 pt-1">
                                  <app-entry class="entry-dark-bg" title="Member number"
                                    [control]="item.controls.memberNumber">
                                  </app-entry>
                                </div>
                              </div>
                              <div class="row" *ngIf="item.value.usi">
                                <div class="col col-md-12 p-0">
                                  <app-entry-radio class="transfer-options" [customList]="transferOptions$"
                                    [control]="item.controls.wholeAmount"></app-entry-radio>
                                </div>
                              </div>
                              <div class="row" *ngIf="item.value.wholeAmount == '2'" appanimatecss>
                                <div class="col col-md-12 p-0 pt-3">
                                  <app-entry-number class="entry-dark-bg" title="Amount to transfer"
                                    [control]="item.controls.amountToTransfer">
                                  </app-entry-number>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col col-md-12 p-0 mt-3" *ngIf="form$ | async; let form">
                                <div class="d-flex">
                                  <app-button class=" center add-superfund" title="Add another super fund" color="light"
                                    [isDisabled]="form.isInvalid" (onClick)="onAddSuperFundClick(form)">
                                  </app-button>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col col-md-12  p-0 description footer important">
                                <div class="note">
                                  <h5>This is important.</h5>
                                  <p>
                                    You are giving permission to Verve Super to transfer the specified balance from the
                                    super fund(s) that you have indicated above into your Verve Super account.
                                  </p>
                                  <p>
                                    You understand that by transferring the whole balance from the super fund(s) you
                                    have
                                    indicated above, the accounts you have with those super funds will be closed and
                                    that
                                    any insurance cover you hold with those funds will cease.
                                  </p>
                                  <p>
                                    Please consider the impact of any loss of insurance cover. If you have a
                                    pre-existing
                                    condition, it may be difficult for you to obtain any or equal cover with another
                                    provider.
                                  </p>
                                  <p>
                                    A transfer takes between 3-28 business days to process. We will send you a
                                    confirmation email when the funds have arrived in your Verve account.
                                  </p>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="card-bottom row mt-3 mb-4">
                            <div class="col col-md-12 pl-0 pr-0">
                              <div class="d-flex ">

                                <app-button class=" back-btn" title="Back" color="light" icon="west"
                                  (onClick)="onMoveBackClick(stepper)">
                                </app-button>
                                <div class="flex-fill"></div>
                                <app-button class=" custom" title="Accept" [isDisabled]=" (isLoading$ | async)" loading
                                  icon="east" (onClick)="onCompleteClick(form)">
                                </app-button>
                              </div>
                              <a class="float-right mt-2   click" (click)="onSkipRollin(form,stepper)">
                                Continue without transferring
                              </a>

                            </div>
                          </div>
                          <div class="footer footer-mobile">
                            <ng-template class="" [ngTemplateOutlet]="FooterStep5"></ng-template>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                  </div>
                </div>

              </mat-step>

              <mat-step>
                <div class="d-flex flex-fill content-steps step-7">
                  <div class="w-50 member-join-bg d-flex flex-column flex-fill">
                    <div class="pt-5 pl-5 d-flex">
                      <app-button class="go-home-btn " title="Back to home" icon="west"
                        (onClick)="goBackToHome()"></app-button>
                    </div>
                    <div class="member-join-bg-inner">
                      <a href="http://vervesuper.com.au/"><img class="member-join-logo"
                          src="https://vervassets.blob.core.windows.net/web/verve-super-small-logo.svg"
                          loading="lazy"></a>
                      <h1 class="mt-3 bold">Congratulations!</h1>
                      <h3 class="subheader">You're a Verve member! </h3>
                      <img class="member-join-line" src="https://vervassets.blob.core.windows.net/web/nmj-line.png"
                        loading="lazy">
                      <div class="footer">
                        <div class="footer footer-desktop">
                          <ng-template class="" [ngTemplateOutlet]="FooterStep6"></ng-template>
                        </div>
                        <!-- <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
                      </h5>
                      <p>
                        All information is of a general nature only. It is important to do your own research and
                        consider things like fees, investment performance, insurance, risk, and alignment with your
                        values when considering if Verve Super is right for you. Consider seeking financial advice.
                        When considering performance, past performance is not indicative of future performance. <a
                          href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
                      </p> -->
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-flex align-items-center content-margin">
                    <div class="main-container">
                      <div class="content ">
                        <div class="row">
                          <div class="col col-md-12 p-0 done">
                            <div class="ng-progressbar" *ngIf="(currentStep$ | async) >0">
                              <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
                            </div>
                            <h3>This is how we feel about welcoming you to Verve!</h3>
                            <div class="privacy d-flex gif">
                              <div class="done-gif-container">
                                <img class="done-gif"
                                  src="https://vervassets.blob.core.windows.net/web/join-form-spice-girls.webp"
                                  loading="lazy">
                              </div>
                              <div class="flex-fill pl-3 next">
                                <h3 class="whatsnext">So what's next?</h3>
                                <div class="d-flex ">
                                  <img src="https://vervassets.blob.core.windows.net/web/nmj-icon4.png" loading="lazy"
                                    class="icon icon1">
                                  <!-- <i class="em em-love_letter mr-2" style="min-width: 45px;"></i> -->
                                  <p>An email has just arrived in your inbox with
                                    instructions on how to login to your account and sort out your insurances.</p>
                                </div>
                                <div class="d-flex">
                                  <img src="https://vervassets.blob.core.windows.net/web/nmj-icon2.png" loading="lazy"
                                    class="icon icon2">
                                  <!-- <i class="em em-money_with_wings mr-2" style="min-width: 45px;"></i> -->
                                  <p>In just a few minutes you’ll get an email on
                                    how to start building your super savings. This includes an email to forward to your
                                    employer/s (if you have one).</p>
                                </div>
                                <div class="d-flex">
                                  <img src="https://vervassets.blob.core.windows.net/web/nmj-icon1.png" loading="lazy"
                                    class="icon icon3">
                                  <!-- <i class="em em-hugging_face mr-2" style="min-width: 45px;"></i> -->
                                  <p>Don’t be a stranger. <a class="contact-us"
                                      href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us</a>, if
                                    you have any
                                    questions.</p>
                                </div>
                              </div>

                            </div>
                            <div class="privacy  ">
                              <div class="keep-touch d-flex align-items-center">
                                <h4 class="flex-fill mb-0">Let's keep in touch
                                  <img src="https://vervassets.blob.core.windows.net/web/nmj-icon3.png" loading="lazy"
                                    class="icon icon3">

                                </h4>
                                <div class="social">

                                  <a class="no-text-decoration" href="https://www.instagram.com/verve.super"
                                    target="_blank"><img loading="lazy"
                                      src="https://vervassets.blob.core.windows.net/web/nmj-linkedin.png"></a>

                                  <a class="no-text-decoration" href="https://www.facebook.com/vervesuper/"
                                    target="_blank"><img loading="lazy"
                                      src="https://vervassets.blob.core.windows.net/web/nmj-facebook.png"></a>

                                  <a class="no-text-decoration" href="https://twitter.com/vervesuper"
                                    target="_blank"><img loading="lazy"
                                      src="https://vervassets.blob.core.windows.net/web/nmj-twitter.png"></a>
                                  <!-- <a class="no-text-decoration" href="https://vervesuper.com.au/" target="_blank"><img
                                      src="https://vervassets.blob.core.windows.net/web/LINK-Social-Icon-NAVY2.png"></a> -->
                                </div>
                              </div>
                            </div>
                            <div class="footer footer-mobile">
                              <ng-template class="" [ngTemplateOutlet]="FooterStep6"></ng-template>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- <div class="row">
    <div class="footer mobile w-100" *ngIf="(currentStep$ | async) == 0">
      <h5>Need help? <a href="mailto:hello@vervesuper.com.au" target="_blank">Contact us.</a></h5>
      <p>
        By providing your email address, you consent and authorise us to send you communications or information,
        including information required by law, via email or similar technologies. Your details will never be passed onto
        a third party other than in accordance
        with our <a href="https://vervesuper.com.au/privacy-policy/" target="_blank">Privacy Policy</a>. You can elect
        to receive communications by post at any time by contacting Verve Super
        on 1300 799 482 or via email at <a href="mailto:hello@vervesuper.com.au"
          target="_blank">hello@vervesuper.com.au</a> or in writing at PO Box 909, Byron Bay, NSW 2481.
      </p>
      <p>
        All information is of a general nature only. As always, it’s important to do your own research and consider
        things like fees, investment performance, insurance, risk profile, and alignment with your values when
        considering if Verve Super is right for
        you. Consider seeking financial advice if you need help determining if Verve Super aligns with your financial
        needs. When considering performance, past performance is not indicative of future performance. <a
          href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
      </p>
    </div>

    <div class="footer mobile w-100" *ngIf="(currentStep$ | async) >0 && (currentStep$ | async) <7">
      <h5>Need help? <a href="mailto:hello@vervesuper.com.au" target="_blank">Contact us.</a></h5>
      <p>
        All information is of a general nature only. It is important to do your own research and consider things like
        fees, investment performance, insurance, risk, and alignment with your values when considering if Verve Super is
        right for you. Consider seeking
        financial advice. When considering performance, past performance is not indicative of future performance. <a
          href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
      </p>
    </div>

    <div class="footer mobile w-100" *ngIf="(currentStep$ | async)  == 7">
      <h5>Need help? <a href="mailto:hello@vervesuper.com.au" target="_blank">Contact us.</a></h5>
      <p>
        A partial rollover will keep your existing account open and any insurance cover you hold through the account
        will continue subject to eligibility requirements and the ability to continue to pay premiums. </p>
      <p>pAll information
        is of a general nature only. It
        is important to do your own research and consider things like fees, investment performance, insurance, risk, and
        alignment with your values when considering if Verve Super is right for you. Consider seeking financial advice.
        When considering
        performance, past performance is not indicative of future performance. <a
          href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>

      </p>


    </div>
    <div class="footer mobile" *ngIf="(currentStep$ | async)  == 8">
      <h5>Need help? <a href="mailto:hello@vervesuper.com.au" target="_blank">Contact us.</a></h5>
      <p>
        All information is of a general nature only. It is important to do your own research and consider things like
        fees, investment performance, insurance, risk, and alignment with your values when considering if Verve Super is
        right for you. Consider seeking
        financial advice. When considering performance, past performance is not indicative of future performance. <a
          href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
      </p>

    </div>
  </div> -->
</div>

<ng-template #FooterStep1>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    All information is of a general nature only. It is important to do your own research and
    consider things like
    fees, investment performance, insurance, risk, and alignment with your values when considering
    if Verve Super is
    right for you. Consider seeking
    financial advice. When considering performance, past performance is not indicative of future
    performance. <a href="https://vervesuper.com.au/fund-information/" target="_blank">Fund
      information.</a>

  </p>
</ng-template>

<ng-template #FooterStep2>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    All information is of a general nature only. It is important to do your own research and
    consider things like fees, investment performance, insurance, risk, and alignment with your
    values when considering if Verve Super is right for you. Consider seeking financial advice.
    When considering performance, past performance is not indicative of future performance. <a
      href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>

  </p>
</ng-template>

<ng-template #FooterStep3>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    All information is of a general nature only. It is important to do your own research and
    consider things like fees, investment performance, insurance, risk, and alignment with your
    values when considering if Verve Super is right for you. Consider seeking financial advice.
    When considering performance, past performance is not indicative of future performance. <a
      href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>

  </p>
</ng-template>


<ng-template #FooterStep4>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    All information is of a general nature only. It is important to do your own research and
    consider things like fees, investment performance, insurance, risk, and alignment with your
    values when considering if Verve Super is right for you. Consider seeking financial advice.
    When considering performance, past performance is not indicative of future performance. <a
      href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
  </p>
</ng-template>

<ng-template #FooterStep5>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    A partial rollover will keep your existing account open and any insurance cover you hold
    through the account
    will continue subject to eligibility requirements and the ability to continue to pay premiums.
  </p>
  <p>
    All information
    is of a general nature only. It
    is important to do your own research and consider things like fees, investment performance,
    insurance, risk, and
    alignment with your values when considering if Verve Super is right for you. Consider seeking
    financial advice.
    When considering
    performance, past performance is not indicative of future performance. <a
      href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>

  </p>
</ng-template>

<ng-template #FooterStep6>
  <h5>Need help? <a href="https://vervesuper.com.au/contact-us/" target="_blank">Contact us.</a>
  </h5>
  <p>
    All information is of a general nature only. It is important to do your own research and
    consider things like fees, investment performance, insurance, risk, and alignment with your
    values when considering if Verve Super is right for you. Consider seeking financial advice.
    When considering performance, past performance is not indicative of future performance. <a
      href="https://vervesuper.com.au/fund-information/" target="_blank">Fund information.</a>
  </p>
</ng-template>

import { KeyValueModel } from './../../model/keyValueModel';
import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { IMemberJoinState } from './state';

const state = (state: AppState) => state.memberJoinState;

export const memberJoin_Form = createSelector(
  state,
  (state: IMemberJoinState) => state.form
);

export const memberJoin_ValidateEmailToken = createSelector(
  state,
  (state: IMemberJoinState) => state.form.value.emailValidatedToken
);

export const memberJoin_CurrentStep = createSelector(
  state,
  (state: IMemberJoinState) => state.currentStep
);

export const memberJoin_ErrorPage = createSelector(
  state,
  (state: IMemberJoinState) => state.errorPage
);

export const memberJoin_IsMobileVerified = createSelector(
  state,
  (state: IMemberJoinState) => state.isMobileVerified
);

export const memberJoin_SendMobileCodeMessage = createSelector(
  state,
  (state: IMemberJoinState) => state.message
);

export const memberJoin_TotalStep = createSelector(
  state,
  (state: IMemberJoinState) => state.totalSteps
);

export const memberJoin_Validated = createSelector(
  state,
  (state: IMemberJoinState) => state.validated
);

export const memberJoin_InvestmentOption = createSelector(
  state,
  (state: IMemberJoinState) => state.form.value.investmentOption
);



export const memberJoin_TransferOptions = createSelector(
  state,
  (state: IMemberJoinState) => [
    { key: '1', value: 'Whole balance' } as KeyValueModel,
    { key: '2', value: 'Specific amount' } as KeyValueModel,
  ]
);

import { HelperService } from './../../services/helper.service';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { AppConfigurationRequestAction, AppConfigurationResponseAction } from './common.actions';

@Injectable()
export class CommonEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: HelperService) {
  }

  getSystemConfig$ = createEffect(() => this.actions$.pipe(
    ofType(AppConfigurationRequestAction),
    switchMap((action: any) => this.service.appConfiguration()),
    map((data: any) => {
      return AppConfigurationResponseAction({ payload: data.data });
    })
  ));

}

import { AddressModel } from '@ifaa-components/ui-components';

import { IMemberJoinState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate,
  onNgrxFormsAction, SetValueAction, setValue, addArrayControl, removeArrayControl, updateArray, setErrors, formStateReducer, SetErrorsAction
} from 'ngrx-forms';
import { MemberJoinModel, SuperFundModel } from 'src/app/model/member-join.model';
import {
  NextStepAction, ValidateEmailResponseAction, PreviousStepAction, ValidateFormAction,
  AddSuperFundAction, RemoveSuperFundAction, SelectInvestmentOptionAction,
  CheckEmailCodeResponseAction, CheckMobileCodeResponseAction, ResendEmailResponseAction,
  SendMobileCodeResponseAction, SelectedProviderAction, GoToStepAction, ValidateMobileResponseAction,
  CreateLeadResponseAction, SkipRollinAction, SkipTFNAction, MoveNextPageResponseAction,
  CompleteResponseAction,
  SetErrorPageDataAction
} from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { email, number, required, requiredTrue, RequiredValidationError } from 'ngrx-forms/validation';
import { Validators } from '@angular/forms';

const formName = 'memberJoinForm';
var helper = new Helper();

const state: IMemberJoinState = {
  form: createFormGroupState(formName, new MemberJoinModel()),
  currentStep: 0,
  totalSteps: 6,
  validated: false,
  isMobileVerified: false,
  message: '',
  errorPage: null
};

const validateForm0 = updateGroup<MemberJoinModel>({
  firstName: validate(required),
  lastName: validate(required),
  email: validate(required, email),
});

const validateForm1 = updateGroup<MemberJoinModel>({
  firstName: validate(required),
  mobile: validate(required),
  lastName: validate(required),
  dob: validate(required),
  gender: validate(helper.requiredIf((value) => value == 0)),
  address: updateGroup<AddressModel>({
    lineOne: validate(required),
    state: validate(required),
    country: validate(required),
  })
});

const validateForm2 = updateGroup<MemberJoinModel>({
  mobileCode: validate(required),
});

const validateForm2Validated = updateGroup<MemberJoinModel>({
  //mobileCode :
});

const validateForm3 = updateGroup<MemberJoinModel>({
  readAllInformationAgreement: validate(requiredTrue),
  iUnderstandBalanceInvested: validate(requiredTrue),
  conscentAgreement: validate(requiredTrue),
});

const validateForm4 = updateGroup<MemberJoinModel>({
  tfn: validate(required),
  tfnAgreement: validate(requiredTrue),
});

const validateForm5 = updateGroup<MemberJoinModel>({
  superFundList: updateArray<SuperFundModel>(
    updateGroup<SuperFundModel>({
      usi: validate(required),
      memberNumber: validate(required),
      amountToTransfer: validate(number)
    }),
  )
});
var laststep = 0;
function ValidateStep(state: IMemberJoinState, action) {

  let myForm = state.form;//formGroupReducer(state.form, action);
  let validatedForm = null;
  if (laststep != state.currentStep) {
    laststep = state.currentStep;
    // if (Object.keys(myForm.errors).length > 0) {
    var clone = helper.clone(myForm.value);
    myForm = createFormGroupState(formName, clone);
    // }
  }
  if (state.currentStep == 0) {
    validatedForm = validateForm0(myForm);
  }

  if (state.currentStep == 1) {
    validatedForm = validateForm1(myForm);
  }

  if (state.currentStep == 2) {

    if (!state.isMobileVerified)
      validatedForm = validateForm2(myForm);

  }

  if (state.currentStep == 3) {
    validatedForm = validateForm3(myForm);
  }

  if (state.currentStep == 4) {
    validatedForm = validateForm4(myForm);
  }

  if (state.currentStep == 5) {
    if (myForm.value.superFundList.length == 0) {
      validatedForm = updateGroup(myForm, {
        superFundList: addArrayControl(new SuperFundModel())
      });
      validatedForm = validateForm5(validatedForm);
    }
    else {
      validatedForm = validateForm5(myForm);
    }

  }

  if (validatedForm) {
    return {
      ...state,
      form: validatedForm,
      validated: true
    };
  }
  return state;
}
const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    var validatesStepState = ValidateStep(state, action);

    return validatesStepState;
  }),
  on(ValidateFormAction, (state) => {
    var clone = helper.clone(state);
    return ValidateStep(clone, NextStepAction);
  }),

  on(CreateLeadResponseAction, (state, { data }) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
    };
  }),
  on(CompleteResponseAction, (state, { data }) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
    };
  }),
  on(MoveNextPageResponseAction, (state, { data }) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
      isMobileVerified: false,
      message: ''
    };
  }),
  on(ValidateEmailResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.emailValidatedToken = payload.token;

    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
      form: setValue(clone)(state.form),
    };
  }),
  on(ValidateMobileResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.emailValidatedToken = payload.token;

    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
      form: setValue(clone)(state.form),
    };
  }),
  on(SelectedProviderAction, (state, { index, id, usi, name }) => {
    var clone = helper.clone(state.form.value);

    clone.superFundList[index].providerId = id;
    clone.superFundList[index].usi = usi;
    clone.superFundList[index].nameOfSuperFund = name;

    var newForm = setValue(clone)(state.form);
    // newForm = formStateReducer(newForm, new SetErrorsAction(state.form.controls.superFundList.controls[index].controls.memberNumber.id, {required:{ } as RequiredValidationError<any>}));
    return {
      ...state,
      form: newForm
    };
  }),
  on(SkipRollinAction, (state) => {
    var clone = helper.clone(state.form.value);

    clone.superFundList = [];

    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),

  on(SkipTFNAction, (state) => {
    var clone = helper.clone(state.form.value);

    clone.tfn = '';
    clone.tfnAgreement = false;

    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(ResendEmailResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.emailValidatedToken = payload.token;

    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(CheckEmailCodeResponseAction, (state, { data }) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false,
      isMobileVerified: false,
      message: ''
    };
  }),
  on(SendMobileCodeResponseAction, (state, { data }) => {
    console.log(data);
    return {
      ...state,
      isMobileVerified: data.isMobileVerified,
      validated: false,
      message: data.message
    };
  }),
  on(CheckMobileCodeResponseAction, (state, { data }) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false
    };
  }),
  on(AddSuperFundAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.superFundList.push(new SuperFundModel());
    // form: updateGroup(state.form, {
    //   superFundList: addArrayControl(new SuperFundModel())
    // }),
    return {
      ...state,
      form: setValue(clone)(state.form),
      validated: false
    };
  }),
  on(SelectInvestmentOptionAction, (state, { option }) => {
    var clone = helper.clone(state.form.value);
    clone.investmentOption = option;
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(SetErrorPageDataAction, (state, { data }) => {
    return {
      ...state,
      errorPage: data,
      currentStep: 0
    };
  }),
  on(RemoveSuperFundAction, (state, { index }) => {

    return {
      ...state,
      form: updateGroup(state.form, {
        superFundList: removeArrayControl(index),
      }),
      validated: false
    };
  }),
  on(GoToStepAction, (state, { index }) => {
    return {
      ...state,
      currentStep: index,
      validated: false
    };
  }),
  on(NextStepAction, (state) => {
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false
    };
  }),
  on(PreviousStepAction, (state) => {
    return {
      ...state,
      currentStep: state.currentStep - 1,
      validated: false
    };
  }),
);

export function memberJoinReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}

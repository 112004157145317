import { MemberJoinModel } from 'src/app/model/member-join.model';
import {
  memberJoin_CurrentStep, memberJoin_Form, memberJoin_InvestmentOption,
  memberJoin_TotalStep, memberJoin_TransferOptions, memberJoin_Validated, memberJoin_ValidateEmailToken, memberJoin_IsMobileVerified, memberJoin_SendMobileCodeMessage, memberJoin_ErrorPage
} from './selectors';
import { Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { commomState_AppConfig, commomState_IsLoading } from 'src/app/store/common/common.selectors';
import {
  AddSuperFundAction, CheckEmailCodeRequestAction, CheckMobileCodeRequestAction,
  CompleteRequestAction,
  CreateLeadRequestAction,
  PreviousStepAction, RemoveSuperFundAction, ResendEmailRequestAction,
  SendMobileCodeRequestAction, SelectedProviderAction, SelectInvestmentOptionAction,
  UpdateFieldRequestAction, ValidateEmailRequestAction, ValidateFormAction, ValidateMobileRequestAction, SkipRollinAction, SkipTFNAction, MoveNextPageRequestAction, SetErrorPageDataAction
} from './actions';
import { ValidateEmailModel, ValidateMobileModel } from 'src/app/model/validate-email.model';
import { FormControlState, FormGroupState, markAsTouched, MarkAsTouchedAction, SetErrorsAction } from 'ngrx-forms';
import { CheckEmailCodeModel, CheckMobileCodeModel, SendMobileCodeModel } from 'src/app/model/check-email-code.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ClearSelectedSearchAction } from 'src/app/components/superannuation-provider-search/actions';
import { interval, timer, combineLatest } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Helper } from '@ifaa-components/ui-components';
import { ToastrService } from 'ngx-toastr';
import { IsLoadingAction } from 'src/app/store/common/common.actions';
declare var grecaptcha: any;
declare var window: any;
declare var fbq: any;

@Component({
  selector: 'app-member-join',
  templateUrl: './member-join.component.html',
  styleUrls: ['./member-join.component.scss']
})
export class MemberJoinComponent extends ComponentBase implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  public innerWidth: any;
  progress: number = 10;
  form$ = this.store.pipe(select(memberJoin_Form));
  appConfig$ = this.store.pipe(select(commomState_AppConfig));
  validateEmailToken$ = this.store.pipe(select(memberJoin_ValidateEmailToken));
  transferOptions$ = this.store.pipe(select(memberJoin_TransferOptions));
  validated$ = this.store.pipe(select(memberJoin_Validated));
  totalSteps$ = this.store.pipe(select(memberJoin_TotalStep));
  currentStep$ = this.store.pipe(select(memberJoin_CurrentStep));
  isMobileVerified$ = this.store.pipe(select(memberJoin_IsMobileVerified));
  sendMobileCodeMessage$ = this.store.pipe(select(memberJoin_SendMobileCodeMessage));
  errorPage$ = this.store.pipe(select(memberJoin_ErrorPage));

  investmentOption$ = this.store.pipe(select(memberJoin_InvestmentOption));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  today: Date = new Date();
  helper = new Helper();
  @ViewChild(MatStepper) stepper: MatStepper;
  currentFragment: string = null;
  currentIndex: number = null;
  resendLocked: boolean = false;
  resendLockedTime: number = 0;
  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router) {
    super();

  }

  ngOnInit() {
    super.ngOnInitBase();
    this.innerWidth = window.innerWidth;

    this.sub = this.validated$.subscribe(validated => {
      if (!validated)
        this.store.dispatch(ValidateFormAction());
    })

    this.sub = combineLatest([this.form$, this.isMobileVerified$, this.currentStep$])
      .pipe(
        map(([form, isMobileVerified, currentStep]) => ({ form, isMobileVerified, currentStep })))
      .subscribe(x => {
        if (x.isMobileVerified && x.currentStep == 2) {
          this.store.dispatch(new SetErrorsAction(x.form.controls.mobileCode.id, {}));
        }
      });

    this.sub = this.currentStep$.subscribe(async step => {

      var form = await this.helper.getValue(this.form$);

      this.store.dispatch(ValidateFormAction());

      var totalSteps = await this.helper.getValue(this.totalSteps$);

      var stepName = 'step-' + step.toString();
      if (totalSteps == step) {
        stepName = 'done';
      }

      this.router.navigate(['/'], { fragment: stepName });

      this.setProgressBarValue(this.stepper);
      window.scroll(0, 0);

      if (step == 2) {
        var data = await this.helper.getValue(this.form$);
        this.store.dispatch(SendMobileCodeRequestAction({ data: { mobile: data.value.mobile, forceResend: false } as SendMobileCodeModel }));
      }
    });

    this.route.fragment.subscribe(fragment => {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          currentPage: fragment,
          pageUrl: location.href
        });
        history.pushState({ 'url': location.href }, 'New URL: ' + location.href, location.href);
        var eventname = null;
        switch (fragment) {
          case 'step-0':
            eventname = 'Lead';
            break;
          case 'done':
            eventname = 'Purchase';
            break;
          default:
        }
        if (fbq)
          fbq('track', eventname)
      }
      this.currentFragment = fragment;
    });
  }

  ngAfterContentChecked(): void {
    if (this.stepper && this.stepper.selectedIndex == 0) {
      this.progress = 5;
      return;
    }
    else {
      if (this.stepper)
        this.setProgressBarValue(this.stepper);
    }
  }
  onCloseErrorPageClick() {
    setTimeout(() => { this.store.dispatch(SetErrorPageDataAction({ data: null })); }, 0);

  }
  async onCheckEmailCodeClick(code: string, email: string) {
    this.store.dispatch(CheckEmailCodeRequestAction({
      data: {
        code: code,
        email: email
      } as CheckEmailCodeModel
    }));
  }



  onInvestmentOptionClick(option: number) {
    this.store.dispatch(SelectInvestmentOptionAction({ option: option }));
  }

  onRemoveSuperFundClick(index: number) {
    this.store.dispatch(RemoveSuperFundAction({ index: index }));
  }

  async onInputValueChanged(input: FormControlState<any>, data: MemberJoinModel, event: any) {
    var currentStep = await this.helper.getValue(this.currentStep$);
    this.store.dispatch(UpdateFieldRequestAction({ step: currentStep, field: input.id, data: data }))
  }

  validateEmail(email: string) {
    this.store.dispatch(ValidateEmailRequestAction({ payload: { email } as ValidateEmailModel }));
  }

  validateMobile(mobile: string) {
    this.store.dispatch(ValidateMobileRequestAction({ payload: { mobile } as ValidateMobileModel }));
  }

  resendEmail(email: string) {
    this.store.dispatch(ResendEmailRequestAction({ payload: { email } as ValidateEmailModel }));
  }

  sendMobile(mobile: string) {
    if (this.resendLocked) return;
    this.store.dispatch(SendMobileCodeRequestAction({ data: { mobile: mobile, forceResend: true } as SendMobileCodeModel }));
    this.resendLocked = true;

    //countdown
    const timerInterval$ = interval(1000); //1s
    const timer$ = timer(30000); //30s
    const time = 30;
    this.resendLockedTime = time;

    const countDown$ = timerInterval$.pipe(take(time));
    this.sub = countDown$.subscribe(val => this.resendLockedTime = time - val);
    this.sub = timer$.subscribe(val => {
      this.resendLocked = false;
    }
    );
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSelectedOption(index: number, $event: any) {
    this.store.dispatch(SelectedProviderAction({ index: index, id: $event.id, usi: $event.usi, name: $event.name }));
  }

  onAddSuperFundClick(form: FormGroupState<MemberJoinModel>) {
    this.store.dispatch(AddSuperFundAction());
    this.store.dispatch(ClearSelectedSearchAction());
  }

  async onCheckMobileCodeClick(form: FormGroupState<MemberJoinModel>, code: string) {
    if (form.isInvalid) {
      this.store.dispatch(new MarkAsTouchedAction(form.id));
      return;
    };

    setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)

    this.store.dispatch(CheckMobileCodeRequestAction({
      data: {
        code: code,
      } as CheckMobileCodeModel
    }));
  }


  onCreateLeadClick(form: FormGroupState<MemberJoinModel>) {
    if (form.isInvalid) {
      this.store.dispatch(new MarkAsTouchedAction(form.id));
      return;
    };
    if (grecaptcha) {
      grecaptcha.execute('6LfeW9saAAAAAPWtOiD0ly77pxy3mxibLWJd2-yH', { action: 'submit' }).then((token) => {
        var clone = this.helper.clone(form.value);
        clone.token = token;
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)

        this.store.dispatch(CreateLeadRequestAction({ data: clone }));
      });
    }
    else {
      setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)
      this.store.dispatch(CreateLeadRequestAction({ data: form.value }));
    }
  }

  async onSkipTFN(form: FormGroupState<MemberJoinModel>, stepper: MatStepper) {
    if (await this.isLoading()) return;
    this.store.dispatch(SkipTFNAction());
    this.onMoveNextClick(form, stepper)
  }

  async isLoading() {
    var val = await this.helper.getValue(this.isLoading$);
    return val;
  }

  async onCompleteClick(form: FormGroupState<MemberJoinModel>) {
    if (await this.isLoading()) return;
    if (form.isInvalid) {
      this.store.dispatch(new MarkAsTouchedAction(form.id));
      return;
    };
    var data = await this.helper.getValue(this.form$);
    setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)
    this.store.dispatch(CompleteRequestAction({ data: data.value }));
  }

  async onSkipRollin(form: FormGroupState<MemberJoinModel>, stepper: MatStepper) {

    if (await this.isLoading()) return;

    var data = await this.helper.clone(form.value);
    data.superFundList = [];
    setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)
    this.store.dispatch(CompleteRequestAction({ data: data }));

  }

  async onMoveNextClick(form: FormGroupState<MemberJoinModel>, stepper: MatStepper) {
    if (form.isInvalid) {
      this.store.dispatch(new MarkAsTouchedAction(form.id));
      return;
    };

    if (await this.isLoading()) return;

    setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)

    var data = await this.helper.getValue(this.form$);
    var clone = this.helper.clone(data.value);

    var currentStep = await this.helper.getValue(this.currentStep$);
    if (currentStep < 5) {
      clone = {
        ...clone,
        superFundList: []
      }
    }
    this.store.dispatch(MoveNextPageRequestAction({ step: currentStep + 1, field: 'page-change', data: clone }))

  }

  async onMoveBackClick(stepper: MatStepper) {
    if (await this.isLoading()) return;

    this.setProgressBarValue(stepper);
    window.scroll(0, 0);
    this.store.dispatch(PreviousStepAction());

  }

  async setProgressBarValue(stepper: MatStepper) {
    if (!stepper) return;
    if (this.currentIndex == stepper.selectedIndex) return;
    window.scroll(0, 0);
    var totalSteps = await this.helper.getValue(this.totalSteps$);
    this.currentIndex = stepper.selectedIndex;
    this.progress = (this.currentIndex * 100) / totalSteps;
    if (this.currentIndex == totalSteps) {
      this.confetti();
    }
  }

  goBackToHome() {
    window.location.href = "https://vervesuper.com.au/"
  }

  frameId: string;
  x: number;
  y: number;

  confetti() {
    window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;

    var canvas = document.querySelector("canvas");
    canvas.style["z-index"] = "10";
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight;
    var ctx = canvas.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    var particles = [];
    var pIndex = 0;
    var x, y, frameId;

    function Dot(x, y, vx, vy, color) {
      this.x = x;
      this.y = y;
      this.vx = vx;
      this.vy = vy;
      this.color = color;
      particles[pIndex] = this;
      this.id = pIndex;
      pIndex++;
      this.life = 0;
      this.maxlife = 600;
      this.degree = getRandom(0, 360);
      this.size = Math.floor(getRandom(8, 15));
    };

    Dot.prototype.draw = function (x, y) {

      this.degree += 1;
      this.vx *= 0.99;
      this.vy *= 0.999;
      this.x += this.vx + Math.cos(this.degree * Math.PI / 180);
      this.y += this.vy;
      this.width = this.size;
      this.height = Math.cos(this.degree * Math.PI / 45) * this.size;
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.moveTo(this.x + this.x / 2, this.y + this.y / 2);
      ctx.lineTo(this.x + this.x / 2 + this.width / 2, this.y + this.y / 2 + this.height);
      ctx.lineTo(this.x + this.x / 2 + this.width + this.width / 2, this.y + this.y / 2 + this.height);
      ctx.lineTo(this.x + this.x / 2 + this.width, this.y + this.y / 2);
      ctx.closePath();
      ctx.fill();
      this.life++;
      if (this.life >= this.maxlife) {
        delete particles[this.id];
      }
    }
    window.addEventListener("resize", function () {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      x = canvas.width / 2;
      y = canvas.height / 2;
    });
    function getColor() {
      var arr = ['teal', '#80a1da', '#8fdad1', '#1e184f', '#a014e6', '#41085e']
      const randomIndex = Math.floor(Math.random() * arr.length);

      // get random item
      const item = arr[randomIndex];

      return item;
    }
    function loop() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (frameId % 3 == 0) {
        new Dot(canvas.width * Math.random() - canvas.width + canvas.width / 2 * Math.random(), -canvas.height / 2, getRandom(4, 8), getRandom(5, 9), getColor());
        new Dot(canvas.width * Math.random() + canvas.width - canvas.width * Math.random(), -canvas.height / 2, -1 * getRandom(4, 8), getRandom(5, 9), getColor());
      }
      for (var i in particles) {
        if (parseInt(i) % 2 == 0) {
          particles[i].draw();
        }
      }
      frameId = requestAnimationFrame(loop);
    }

    loop();

    function getRandom(min, max) {
      return Math.random() * (max - min) + min;
    }
  }

}
